// To get user info from widget

type BusEventCallback<T> = (payload: T | undefined) => void;
// biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
type SubscriptionHandle = void;

export interface SgWidgetsBus {
  dangerouslyGetCurrentValue<T>(topicName: string): T | undefined | null;

  subscribe<T>(topicName: string, cb: BusEventCallback<T>): SubscriptionHandle;

  unsubscribe(handle: SubscriptionHandle): void;
}
export function getWidgetBus(): SgWidgetsBus | null {
  const widgetConfiguration = window.SGWTWidgetConfiguration;
  return widgetConfiguration?.bus ? widgetConfiguration.bus : null;
}

const BUS_TOPIC_SGCONNECT_USERCONNECTION = 'sg-connect.user-connection';

interface UserConnection {
  connected: boolean;
  claims: Record<string, string>;
  mail: string;
}

export function getUserConnection(): UserConnection | undefined | null {
  const bus = getWidgetBus();
  if (bus) {
    return bus.dangerouslyGetCurrentValue<UserConnection>(BUS_TOPIC_SGCONNECT_USERCONNECTION);
  }
  return undefined;
}
