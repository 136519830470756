import { App } from '@App';
// import { applyPolyfills, defineCustomElements } from '@sg-bootstrap/components/dist/loader';
import { isDefined } from '@sgme/fp';
import type { SGWTConnectError } from '@sgwt/connect-core';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import '@sg-bootstrap/core/dist/css/sg-bootstrap-standard.css';
import '@sg-bootstrap/icons/dist/index.css';
import '@sg-bootstrap/ag-grid-theme/dist/29-standard.css';
import { setPermissions } from '@features/authorization/authorization.slice';
import { requestAuthorization } from '@features/sgwtConnect/requestAuthorization';
import { store } from '@store/store';
import { setAgGridLicense } from '@utils/ag-grid';

import './assets/ag-theme-fx.css';
import './assets/ag-theme-lag.css';
import './assets/ag-common.css';
import { SgwtWidgetContextProvider } from '@sgwt/sgwt-widgets-react';

setAgGridLicense();

const renderApp = (): void => {
  const root = createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <StrictMode>
      <SgwtWidgetContextProvider infrastructure="azure">
        <App />
      </SgwtWidgetContextProvider>
      <div id="root-modal" />
    </StrictMode>,
  );
};

const renderError = (authorizationError: SGWTConnectError): void => {
  document.body.innerHTML = `
      <div class='alert alert-danger' role='alert'>
        Authorization error: ${authorizationError}.
      </div>`;
};

requestAuthorization(renderApp, renderError, {
  onUserInfo: (userInfo) => {
    if (isDefined(userInfo)) {
      store.dispatch(setPermissions(userInfo));
    }
  },
});
